import api from "../apiConfig";

const cityService = {
  // GET request to retrieve cities
  getCities: async () => {
    try {
      const response = await api.get("/city?perPage=1000000");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getCity: async (id) => {
    try {
      const response = await api.get(`/city/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new city
  addCity: async (cityData) => {
    try {
      const response = await api.post("/city", cityData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing city
  updateCity: async (id, updateData) => {
    try {
      const response = await api.put(`/city/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a city
  deleteCity: async (id) => {
    try {
      const response = await api.delete(`/city/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default cityService;
