import api from "../apiConfig";

const locationService = {
  // GET request to retrieve locations
  getLocations: async () => {
    try {
      const response = await api.get("/location?perPage=10000000");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getSuperadminLocations: async () => {
    try {
      const response = await api.get(
        "/location/?blockedBusiness=true&perPage=10000000"
      );
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getLocation: async (id) => {
    try {
      const response = await api.get(`/location/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getLocationWQuery: async (query) => {
    try {
      const response = await api.get(`/location?${query}&perPage=1000000000`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new location
  addLocation: async (locationData) => {
    try {
      const response = await api.post("/location", locationData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing location
  updateLocation: async (id, updateData) => {
    try {
      const response = await api.put(`/location/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a location
  deleteLocation: async (id) => {
    try {
      const response = await api.delete(`/location/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
  updateLocationSettings: async (id, updateData) => {
    try {
      const response = await api.put(`/location/settings/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
  addLocationSettings: async (settingData) => {
    try {
      const response = await api.post(`/location/settings`, settingData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
  deleteLocationSettings: async (id) => {
    try {
      const response = await api.delete(`/location/settings/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default locationService;
