import api from "../apiConfig";

const reviewsService = {
  // GET request to retrieve reviews
  getReviews: async () => {
    try {
      const response = await api.get("/review?perPage=1000000");
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },
  getReview: async (id) => {
    try {
      const response = await api.get(`/review/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      throw error;
    }
  },

  // POST request to add a new review
  addReview: async (userData) => {
    try {
      const response = await api.post("/review/", userData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // PUT request to update an existing review
  updateReview: async (id, updateData) => {
    try {
      const response = await api.put(`/review/${id}`, updateData);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },

  // DELETE request to delete a reviews
  deleteReview: async (id) => {
    try {
      const response = await api.delete(`/review/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors
      return error;
    }
  },
};

export default reviewsService;
